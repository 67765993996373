import React, {useEffect, useState} from "react";
import Page from "src/components/page";
import Header from "src/components/header";
import homeClasses from "src/pages/home.module.scss";
import CountryMapImage from "src/components/country-map-image";
import PriceCard from "src/components/price-card";
import Footer from "src/components/footer";
import {
    listPriceCards,
    TYPES_FORM
} from "src/constants/homepage";
import FaqQuestion from "../components/faq-question";
import ContactUs from "../components/contact-us";
import PageTitle from "src/components/page-title";
import DeliveryForm from "../components/delivery-form";
import Carousel from "../components/carousel";
import classnames from 'classnames';
import {ANIMATION_TYPE} from '../constants/animation'
import AOS from 'aos';
import 'aos/dist/aos.css';
import CookieBanner from "../components/cookie-banner/index"
import {KEY_ACCEPTED_COOKIES, QUESTIONS_KE_KE} from "../constants/homepage"
import {getAcceptedCookie} from "../services/acceptedCookie"


const Home = () => {
    const [isAcceptedCookie, setIsAcceptedCookie] = useState(getAcceptedCookie(KEY_ACCEPTED_COOKIES))
    const [typeForm, setTypeForm] = useState(TYPES_FORM.KE_CZ);
    useEffect(() => {
        AOS.init()
    }, [])


    return (
        <Page className={homeClasses.homePage}>
            <div className={homeClasses.wrapper}>
                <Header/>
            </div>
            <section className={homeClasses.topTitleSectionWrapper}>
                <div className={homeClasses.wrapper}>
                    <div className={homeClasses.topTitleSection}>
                        <div className={homeClasses.pageTitle}>
                            <PageTitle title={
                                <div data-aos={ANIMATION_TYPE}>
                                    <span>Expresná doručovacia služba </span>
                                    <span>z Košíc do Česka a v rámci Košíc.</span>
                                </div>
                            }/>
                        </div>
                        <div className={homeClasses.countryImage} data-aos={ANIMATION_TYPE}>
                            <CountryMapImage/>
                        </div>
                        <div className={homeClasses.formWrapper} data-aos={ANIMATION_TYPE}>
                            <div className={homeClasses.formSection}>
                                <div className={homeClasses.tabButtons}>
                                    <button
                                        className={classnames({[`${homeClasses.active}`]: typeForm === TYPES_FORM.KE_CZ})}
                                        onClick={() => setTypeForm(TYPES_FORM.KE_CZ)}>
                                        <span className={homeClasses.desktop}>Z Košíc do Českej republiky</span>
                                        <span className={homeClasses.mobile}>Z Košíc do Česka</span>
                                    </button>
                                    <button
                                        className={classnames({[`${homeClasses.active}`]: typeForm === TYPES_FORM.KE_KE})}
                                        onClick={() => setTypeForm(TYPES_FORM.KE_KE)}>
                                        <span className={homeClasses.desktop}>V rámci Košíc do konca dňa</span>
                                        <span className={homeClasses.mobile}>V rámci Košíc</span>
                                    </button>
                                </div>
                                <DeliveryForm type={typeForm} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classnames(homeClasses.sliderSectionWrapper, homeClasses.wrapper)}
                     data-aos={ANIMATION_TYPE}>
                    <h2 className={homeClasses.title}>Na čo Doruč.to využijem?</h2>
                    <Carousel/>
                </div>
            </section>

            <section data-aos={ANIMATION_TYPE}>
                <div className={homeClasses.wrapper}>
                    <div className={homeClasses.priceSection}>
                        <div className={homeClasses.title}>
                            <h2 className={classnames(homeClasses.title)}>Cenník</h2>
                        </div>
                        <div className={classnames(homeClasses.listPrices)}>
                            {listPriceCards.map((cardOptions, key) => (
                                <div key={key} className={homeClasses.price}>
                                    <PriceCard {...cardOptions} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section data-aos={ANIMATION_TYPE}>
                <div className={homeClasses.wrapper}>
                    <FaqQuestion typeQuestions={QUESTIONS_KE_KE}/>
                </div>
            </section>
            <ContactUs/>
            <Footer/>
            {isAcceptedCookie === null? <CookieBanner setIsAcceptedCookie={setIsAcceptedCookie}/>:""}
        </Page>
    );
};

export default Home;
